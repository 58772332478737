<template>
  <div class="tags_container">
    <div class="tags_result">
      <div class="title_info">{{ tagVal }}</div>
      <template v-if="pageInfo.total > 0">
        <ul class="result_list">
          <li class="list_item" v-for="(item,index) in articleList" :key="index">
            <div class="cover">
              <img :src="item.imageUrl">
            </div>
            <div class="content_container">
              <div class="title" @click="skipDetails(item.atUuid)">{{ item.title }}</div>
              <div class="content" @click="skipDetails(item.atUuid)">{{ item.introduction }}</div>
              <div class="tools">
                <div class="author">
                  <img v-if="item.iconUrl" :src="item.iconUrl"/>
                  <img v-else src="../../assets/image/authorImg.png">
                  <span class="nick_name">{{ item.authorName }}</span>
                </div>
                <ul class="tags">
                  <li v-for="(lable) in item.labelList.slice(0,4)" :key="lable.ltId" @click="shipTagList(lable.ltName)">
                    {{ lable.ltName }}
                  </li>
                </ul>
                <span class="show-time">
                  <span class="date">{{ item.showTime }}</span>
                </span>
              </div>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <el-pagination
            class="pagination"
            background
            @current-change="handleCurrentChange"
            :current-page="pageInfo.pageIndex"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="pageInfo.pageSize"
            layout="prev, pager, next, jumper"
            :total="pageInfo.total">
        </el-pagination>
      </template>
      <template v-else>
        <div style="text-align: center;margin: 40px 0">
          <img src="../../assets/image/no_data.png">
          <div style="font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;">暂无数据
          </div>
        </div>
      </template>

    </div>
    <div class="hot_list">
      <!-- 热门文章 -->
      <hot-article></hot-article>
    </div>
  </div>
</template>

<script>
import HotArticle from "../../components/HotArticle/HotArticle";

export default {
  name: "index",
  components: {HotArticle},
  data: () => ({
    articleList: [],
    tagVal: undefined,
    // 分页对象
    pageInfo: {
      pageIndex: 1,
      pageSize: 10,
      total: 10
    }
  }),
  created() {
    this.tagVal = this.$route.params.tagType
    let params = {
      tag: this.tagVal,
      currentPage: 1,
      currentPageSize: 10
    }
    this.getTags(params);
  },
  beforeRouteUpdate(to, from, next) {
    this.tagVal = to.params.tagType
    let params = {
      tag: to.params.tagType,
      currentPage: 1,
      currentPageSize: 10
    }
    this.getTags(params);
    next();
  },
  methods: {
    shipTagList(name) {
      if (name == this.tagVal) return
      this.$router.push('/tag-list/' + name)
    },
    getTags(params) {
      this.axios.get('/article/list-tag', {params}).then(res => {
        this.articleList = res.page.list
        this.pageInfo.total = res.page.totalCount
        this.pageInfo.pageIndex = res.page.currPage
      })
    },
    // 跳转详情
    skipDetails(atUuid) {
      this.$router.push('/article-detail/' + atUuid)
    },
    //  pageIndex 改变
    handleCurrentChange(val) {
      this.pageInfo.pageIndex = val
      let params = {
        tag: this.tagVal,
        currentPage: this.pageInfo.pageIndex,
        currentPageSize: 10
      }
      this.getTags(params);
    },
  }
}
</script>

<style lang="scss" scoped>
.tags_container {
  display: flex;

  .tags_result {
    flex: 1;
    width: calc(100% - 283px);
    background: #FFFFFF;
    border-radius: 4px;
    font-family: Microsoft YaHei;

    .title_info {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      text-align: center;
      padding: 26px 0;
      border-bottom: 1px solid #EFEFEF;
    }

    .result_list {
      padding: 0 30px;

      .list_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        margin: 0 15px;
        border-bottom: 1px solid #efefef;
        cursor: pointer;


        .cover {
          width: 140px;
          height: 100px;
          margin-right: 20px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .content_container {
          flex: 1;
          width: 0;
          position: relative;
          top: -5px;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 41px;
            color: #000000;
            //margin-bottom: 10px;

            &:hover {
              color: #3E35E7;
            }
          }

          .content {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            font-size: 14px;
            line-height: 30px;
            color: #000000;
            //min-height: 80px;
          }

          .tools {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;

            .author {
              display: flex;
              align-items: center;

              img {
                height: 30px;
                width: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 5px;
              }

              .nick_name {
                width: 70px;
                font-size: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .tags {
              flex: 1;
              display: flex;

              li {
                height: 24px;
                line-height: 24px;
                box-sizing: border-box;
                text-align: center;
                background: #ECECEC;
                font-size: 12px;
                color: #3E35E7;
                border-radius: 12px;
                padding: 0 8px;

                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
            }

            @media (min-width: 1280px) {
              .tags {
                li {
                  &:not(:last-child) {
                    margin-right: 20px;
                  }
                }
              }
            }

            .show-time {
              color: #999999;
            }
          }
        }
      }
    }


    /deep/ .el-pagination.is-background .btn-next, /deep/ .el-pagination.is-background .btn-prev, /deep/ .el-pagination.is-background .el-pager li {
      min-width: 36px;
      height: 36px;
      line-height: 36px;
      border: 1px solid #ECECEC;
      border-radius: 6px;
    }

    /deep/ .el-pagination__jump {
      line-height: 36px;
      height: 36px;

      input:focus {
        border-color: #3E35E7;
      }
    }

    /deep/ .pagination {
      margin: 30px 0;
      text-align: center;

      .el-pager li:not(.disabled).active {
        background: #3E35E7;
      }

      .el-pager li:not(.disabled):not(.active) {
        background: #FFFFFF;
      }

      .el-pager li:not(.disabled):not(.active):hover {
        color: #3E35E7;
      }
    }
  }

  .hot_list {
    width: 283px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }
}
</style>
